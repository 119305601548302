import React from "react";
import { Link } from "gatsby";

export default function ListNote({ edges }) {
  return (
    <>
      {edges.map((edge) => (
        <article className="col-span-12 md:col-span-6 my-2 py-4 md:my-0 border-color-slate-200 border-b last:border-none">
          <div className="items-center uppercase leading-relaxed">
            <time>{edge.node.fields.date}</time>
          </div>
          <Link to={`/${edge.node.fields.slug}`}>
            <img className="my-2 w-full object-cover h-48" src={edge.node.fields.images} alt={edge.node.fields.title} loading="lazy"/>
            <h2 className="hover:underline my-2">{edge.node.fields.title}</h2>
          </Link>
        </article>
      ))}
    </>
  );
}
